<template>
	<div>
		<CCard class="p-4">
			<form ref="form" @submit.prevent="submit">
				<CCardHeader>
					<CRow>
				
						<CCol lg="6">
							<h5 v-if="!editMode">Create Trucker Settlement</h5>
							<h5 v-else>Update Trucker Settlement / {{dataParams.invoice_no}}</h5>
						</CCol>
						<CCol lg="6">
							<div style="text-align: right">
								<CButton size="sm" class="btn btn-ghost-dark" @click="backToTable()"><i
										class="fa fa-close"></i> Cancel</CButton> &nbsp;
								<CButton size="sm" color="info" id="btn_submit" type="submit"><i class="fa fa-save"></i>
									Save</CButton>
							</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CCard class="p-2">
						<CRow>
							<CCol lg="12">
								<strong style="font-size:13px" class="text-dark">Trucker Settlement Information</strong>
							</CCol>
						</CRow>
						<br />
					
						<CCol lg="4">
							<h4 class="text-success" style="text-align: right;" v-if="isLoading">Uploading...({{uploadPercentage}}%)</h4>
						</CCol> 	
							<CRow> 
							<CCol lg="6">        
										<div class="form-group"> 
											<label>Vendor/Trucker <span class="text-danger">*</span></label>
											<v-select 
												label="vendor_name" 
												:options="vendorList.data"
												:reduce="item => item.id"
												v-model="dataParams.vendor_id"
												placeholder="-Select Vendor/Trucker-" 
											>
												<template #search="{attributes, events}">
													<input
														class="vs__search"
														:required="!dataParams.vendor_id"
														v-bind="attributes"
														v-on="events"
													/>
												</template>
											</v-select>
										</div>
							</CCol>
							<CCol lg="6">        
										<div class="form-group"> 
											<label>Customer </label>
											<v-select 
												label="customer_name" 
												:options="customerList.data"
												:reduce="item => item.id"
												v-model="dataParams.customer_id"
												placeholder="-Select customer-" 
											>
												
											</v-select>
										</div>
							</CCol>
							<CCol lg="4">
										<CInput
										
										label="Ref. No."
										placeholder="Enter ref no."
										v-model="dataParams.ref_no" 
										/>
							</CCol>
							
							<CCol lg="4">
										<div class="form-group"> 
											<label>Tax Type <span class="text-danger">*</span></label>
											<v-select 
												label="setting_name" 
												:options="[
													{
														setting_name: 'VAT',
														id: 'vat'
													},
													{
														setting_name: 'Non-VAT',
														id: 'nonvat'
													},
												]"
												:reduce="item => item.id"
												v-model="dataParams.tax_type"
												placeholder="-Select tax type-" 
											>
												<template #search="{attributes, events}">
													<input
														class="vs__search"
														:required="!dataParams.tax_type"
														v-bind="attributes"
														v-on="events"
													/>
												</template>
											</v-select>
										</div>
							</CCol>
							<CCol lg="4">
										<div class="form-group"> 
											<label>Status <span class="text-danger">*</span></label>
											<v-select 
												label="setting_name" 
												:options="[
													{
														setting_name: 'Cancelled',
														id: 'cancelled'
													},
													{
														setting_name: 'Billed',
														id: 'billed'
													},
													{
														setting_name: 'Unbilled',
														id: 'unbilled'
													}
												]"
												:reduce="item => item.id"
												v-model="dataParams.status"
												placeholder="-Select Status-" 
											>
												<template #search="{attributes, events}">
													<input
														class="vs__search"
														:required="!dataParams.status"
														v-bind="attributes"
														v-on="events"
													/>
												</template>
											</v-select>
										</div>
							</CCol>
							</CRow>
								
					
							<CRow>
								<CCol lg="12">
									<strong style="font-size:13px" class="text-dark">Covered Trips</strong>
								</CCol>
							</CRow>
							<br />
								
							<CRow > 
									<CCol lg="3">
										<div class="form-group">
											<label for="exampleInputEmail1">Delivery Date From <span class="text-danger">*</span></label>
											
											<Datepicker 
												input-class="form-control bg-white" 
												v-model="dataParams.delivery_date_from"
												placeholder="Delivery Date From" 
												:typeable="true" 
												required 
											>
											</Datepicker>  
											<!-- <Datetime format="MM-DD-YYYY" v-model="dataParams.delivery_date_from" required></Datetime> -->
										</div> 
									</CCol>  
									<CCol lg="3">
										<div class="form-group">
											<label for="exampleInputEmail1">Delivery Date To <span class="text-danger">*</span></label>
											<Datepicker 
												input-class="form-control bg-white" 
												v-model="dataParams.delivery_date_to"
												placeholder="Delivery Date To" 
												:typeable="true" 
												required 
											>
											</Datepicker>  
											
											<!-- <Datetime format="MM-DD-YYYY" v-model="dataParams.delivery_date_to" required></Datetime> -->
										</div> 
									</CCol>  
									
									<CCol lg="3">
										<div class="form-group"> 
											<label>Origin</label>
											<v-select 
												label="setting_name" 
												:options="originList.data"
												:reduce="item => item.id"
												v-model="dataParams.origin_id"
												placeholder="-Select origin-" 
											>
												
											</v-select>
										</div>
										
									</CCol> 
									<CCol lg="3">
										<div class="form-group"> 
											<label>Depot</label>
											<v-select 
												:disabled="depot_id != null"
												label="setting_name" 
												:options="depotList.data"
												:reduce="item => item.id"
												v-model="dataParams.depot_id"
												placeholder="-Select depot-" 
											>
												
											</v-select>
										</div>
									</CCol>
									<CCol lg="12">
										<div style="text-align: right" class="mt-4">  
											<CButton size="sm" color="secondary" class="px-3" @click="clearSearch">Clear</CButton> &nbsp;
											<CButton size="sm" color="info" @click="validate"><i class="icon-magnifier"></i> Search</CButton> 
											
										</div>
									</CCol>
									
							</CRow>  
				
					</CCard>
					
						<CRow> 
							<CCol lg="12">
								<CDataTable
							      	:items="displayedBooking"
							      	:fields="fields"
									hover
									striped
									small
									fixed
									:loading="searching"
							    >  
							    	<template #status="{item}">
								        <td style="padding: 5px; width: 100px">
								        	{{item.status}}
								        </td>
							      	</template> 
							    </CDataTable> 
							    <CPagination
						          	:active-page.sync="currentPage"
						          	:pages="Math.ceil(bookingList.total / 20)"
						          	:activePage="currentPage"
						          	@update:activePage="updatePage"
						        />
							</CCol>  
						</CRow> 
						<CRow>
						<CCol lg="9" class="text-right"></CCol>
						<CCol lg="3" class="text-right">
							<ul class="list-group list-group-flush">
								<!-- <li class="list-group-item d-flex justify-content-between align-items-center">
									Sub Total
									<strong>{{dataParams.sub_total | number_format(null, 2) }}</strong>
								</li>
								<li class="list-group-item d-flex justify-content-between align-items-center">
									Tax (12%)
									<strong>{{dataParams.tax_computation | number_format(null, 2) }}</strong>
								</li> -->
								<li class="list-group-item d-flex justify-content-between align-items-center" style="font-weight:700; font-size:18px">
									TOTAL
									<strong>{{dataParams.total_amount | number_format('currency')}}</strong>
								</li>
								
							</ul>
							
						</CCol>
					</CRow>
				
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../../style.css';
import Datetime from 'vuejs-datetimepicker'; 
import moment from 'moment';

export default {
	mounted(){  

		this.getCustomer(); 
		this.getVendor();  
		this.getOrigin();   
		this.getDepot();
		 
		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			currentPage: 1,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",  
			dataParams: {
				customer_id: "",
				vendor_id: "",
				origin_id: "", 
				ref_no: "",
				status: "unbilled",
				tax_type: "vat",
				delivery_date_from: "",
				delivery_date_to: "",
				total_amount: 0.00,
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : null,
				// date_billed: "",
			},
			editMode: false,
			selectedRow: {},
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			
			
			isLoading: false,
			uploadPercentage: 0,
			config,


			customerList: {
				data: []
			},
			vendorList: {
				data: []
			},
			bookingTypeList: {
				data: []
			},
			commodityTypeList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			destinationList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			driverList: {
				data: []
			},
			depotList: {data:[]},
			fields:  [
				{
					key: 'booking_no', 
					label: 'Booking #'
				},
				{
					key: 'customer_id_label', 
					label: 'Customer'
				}, 
				{
					key: 'vehicle_type_id_label', 
					label: 'Trucker'
				}, 
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date'
				},   
				{
					key: 'plate_no', 
					label: 'Plate #'
				}, 
				{
					key: 'origin_id_label', 
					label: 'Origin'
				}, 
				{
					key: 'depot_name',
					label : 'Depot'
				},
				// {
				// 	key: 'no_of_trips', 
				// 	label: 'No. of trips'
				// }, 
				{
					key: 'subcon_rate', 
					label: 'Subcon Rate'
				},
				{
					key: 'toll_fee', 
					label: 'Toll Fee'
				},
				{
					key: 'other_expenses', 
					label: 'Other Expenses'
				},
				{
					key: 'total_amount', 
					label: 'Total Amount'
				}
			],
			displayedBooking: [],
			bookingList: {
				data: [],
				total: 0
			},
			disableSubmit: false,
			searching: false
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Datetime},
	methods: {  
		getDepot(){

          axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=100000')
          .then(response => {
            this.depotList = response.data;
            this.depotList.data = this.depotList.data.map((value, index)=>{
              value.value = value.id;
              value.label = value.setting_name;
              return value;
            })

            console.log(this.depotList)
          })

        }, 
		updatePage(data){
			this.currentPage = data; 
			this.displayedBooking = [...this.bookingList.data];  
			if(this.currentPage > 1){
				this.displayedBooking.splice(0, ((this.currentPage-1) * 20));
				this.displayedBooking.splice(((this.currentPage-1) * 20), this.bookingList.data.length) 
			}
			else{ 
				this.displayedBooking.splice(20, this.bookingList.data.length) 
			}
		}, 
		 backToTable(){
	    	this.$router.push('/main/trucker_settlement')
	    },

 
	    submit(){

	    	if(this.disableSubmit){
	    		this.getBookings();
	    		return;
	    	}

	    	if(this.bookingList.data.length == 0){
	    		Swal.fire({
					title: 'Ooooppss!',
					text: "No bookings found!",
					icon: 'warning', 
				})
				return;
	    	}
	    	if(this.dataParams.origin_id == ""){
	    		this.dataParams.origin_id = null;
	    	} 		

	    	this.dataParams.bookings = this.bookingList.data.map((value, index)=>{return{id: value.id}});

			for (const key in this.dataParams) {
				if (this.dataParams.hasOwnProperty(key)) {
					this.dataParams[key] = this.dataParams[key] ? this.dataParams[key] : null;
				}
			}

	    	var ax = {};
	    	this.dataParams.delivery_date_from = moment(this.dataParams.delivery_date_from).startOf('day').unix();
	    	this.dataParams.delivery_date_to = moment(this.dataParams.delivery_date_to).startOf('day').unix()+(86399);
	    	console.log(this.dataParams);
			
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/trucker_settlement/"+this.$route.params.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/trucker_settlement", this.dataParams)
	    	}
			this.$showLoading(true);
	    	ax.then(response => {
				this.$showLoading(false);
	    		var text = 'Trucker settlement successfully added!';
	    		if(this.editMode){
	    			text = 'Trucker settlement successfully updated!';
	    		}
	    		
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					customer_id: "",
					origin_id: "",
					vendor_id: "",
					ref_no: "",
					status: "",
					tax_type: "vat",
					delivery_date_from: "",
					delivery_date_to: "",
					total_amount: "",
					// date_billed: "",
					bookings: []
				}
				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
				.then((result) => { 
					this.bookingList.data = [];
	    		this.$router.push('/main/view_trucker_settlement/'+response.data.data.id)
				})
				
	    	})
			.catch(err => {
				this.$showLoading(true);
			})
	    }, 


		getCustomer() {
			axios.get(config.api_path + '/reference/customer-list', {
				params: {
					show_all: true
				}
			})
				.then(response => {
					this.customerList = response.data;
				})
		},

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.filter((value, index)=>{
	    			return value.status == 'active' 
					&& value.vendor_type_name.setting_name.toLowerCase() == 'trucker'
					|| value.vendor_type_name.setting_name.toLowerCase() == 'trucking services';
	    		}) 
	    	})

	    }, 

	    getOrigin() {
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'origin'
				}
			})
				.then(response => {
					this.originList = response.data;
				})
		},
	    validate(){
	    	this.disableSubmit = true;
			document.getElementById('btn_submit').click();
	    },
		
 
		getBookings(){  
			
			this.searching = true;
	    	var from = moment(this.dataParams.delivery_date_from).startOf('day').unix();
	    	var to = moment(this.dataParams.delivery_date_to).startOf('day').unix()+(86399);
	    	this.dataParams.total_amount = 0.00;
	    	var trucker_settlement_id = null;
	    	if(this.$route.params.id != undefined){
	    		trucker_settlement_id = this.$route.params.id;
	    	}
	    	var origin_id = this.dataParams.origin_id;
	    	if(origin_id == null){
	    		origin_id = ""
	    	}
			var customer_id = this.dataParams.customer_id;
	    	if(customer_id == null){
	    		customer_id = ""
	    	}
	    	var vendor_id = this.dataParams.vendor_id;
	    	if(vendor_id == null){
	    		vendor_id = ""
	    	}
			let depot_id = this.dataParams.depot_id ?? null;

	    	// axios.get(config.api_path+'/booking?is_subcon=1&trucker_settlement_id='+trucker_settlement_id+'&status=completed&from='+from+'&to='+to+'&origin_id='+origin_id+'&customer_id='+customer_id+'&vendor_id='+vendor_id+'&page='+1+'&limit=10000000')
			axios.get(`${config.api_path}/booking`,{
				params: {
					is_subcon : 1,
					trucker_settlement_id,
					status : 'completed',
					from,
					to,
					origin_id,
					customer_id,
					vendor_id,
					depot_id,
					page: 1,
					limit: 1000000
				}
			})
	    	.then(response => { 
				let over_total_amount = 0;
				let sub_total = 0;
				let tax_computation = 0;
	    		this.disableSubmit = false;
	    		this.bookingList = {...response.data}; 

				
				// for(var i = 0; i < this.bookingList.data.length; i++){
	    		// 	if(this.bookingList.data[i].subcon_rate != null && this.bookingList.data[i].subcon_rate != ""){
	    		// 		this.dataParams.total_amount += parseFloat(this.bookingList.data[i].subcon_rate);
	    		// 	} 
	    		// }

	    		this.bookingList.data = this.bookingList.data.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
					
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					
					if(value.depot_id){
	    				value.depot_name = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_name = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.commodity_id){
	    				value.commodity_id_label = value.commodity.setting_name
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
	    			else{
	    				value.vehicle_type_id_label = "N/A"
	    			}

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}
					if(value.asset_type == 'vehicle'){
	    				value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			}
	    			if(value.asset_type == 'equipment'){
	    				value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			}
	    			if(value.asset_type == 'furnishing'){
	    				value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			}
	    			value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			if(value.client_ref_no == "" || value.client_ref_no == null){
	    				value.client_ref_no_label = "N/A";
	    			}


	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}

	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			// if(value.total_amount){
	    			// 	value.total_amount = value.total_amount;
	    			// }
	    			// else{
	    			// 	value.total_amount = "0.00"
	    			// }

	    			if(value.client_rate){
		    			value.client_rate= value.client_rate
		    		}
	    			
					else{
	    				value.client_rate = "0.00" 
	    			}

	    			if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = "Company Owned"
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

					value.toll_fee = (value.billable) ? value.billable.toll_fee : '0.00';
					value.other_expenses = (value.billable) ? value.billable.other_expenses : '0.00';

					let total_amount = 0;

					if(value.billable) {
						total_amount = parseFloat(value.billable.toll_fee) + parseFloat(value.billable.other_expenses);
					}
					value.total_amount =  total_amount + parseFloat(value.subcon_rate);
					value.total_amount = (this.dataParams.tax_type && this.dataParams.tax_type == 'vat') ? value.total_amount + (value.total_amount * 0.12) : value.total_amount;
					value.total_amount = value.total_amount.toFixed(2)
					over_total_amount += parseFloat(value.total_amount);

					value.depot_name = (value.depot) ? value.depot.setting_name : 'N/A';

					sub_total = total_amount + parseFloat(value.subcon_rate);
					tax_computation = (this.dataParams.tax_type && this.dataParams.tax_type == 'vat') ? sub_total * 0.12 : 0.00;

					return value; 
	    		}); 

	    		this.searching = false;  
				this.dataParams.total_amount = over_total_amount.toFixed(2);
				this.dataParams.sub_total = sub_total;
				this.dataParams.tax_computation = tax_computation;
	    		this.displayedBooking = [...this.bookingList.data];  
				
	    		this.displayedBooking.splice(((this.currentPage-1) + 20), this.bookingList.data.length)
	    	})
	    	.catch(e=>{
				console.error(e)
	    		this.searching = false;
	    		this.disableSubmit = false;
	    	})
	    },

	    getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/trucker_settlement/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data;  
				this.dataParams.total_amount = parseFloat(this.dataParams.total_amount);
				let over_total_amount = 0;
				let sub_total = 0;
				let tax_computation = 0;
				this.dataParams.delivery_date_from =moment(new Date(this.dataParams.delivery_date_from * 1000)).format('MM-DD-YYYY')
				this.dataParams.delivery_date_to =moment(new Date(this.dataParams.delivery_date_to * 1000)).format('MM-DD-YYYY')
				this.bookingList.data = (this.dataParams.booking) ? this.dataParams.booking.map((value, index)=>{
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "N/A"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "N/A"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "N/A"
	    			}
					if(value.depot_id){
	    				value.depot_name = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_name = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.commodity_id){
	    				value.commodity_id_label = value.commodity.setting_name
	    			}
	    			else{
	    				value.commodity_id_label = "N/A"
	    			}

	    			if(value.destination_id){
	    				value.destination_id_label = value.destination.setting_name
	    			}
	    			else{
	    				value.destination_id_label = "N/A"
	    			}

	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
	    			else{
	    				value.vehicle_type_id_label = "N/A"
	    			}

					// if(value.asset_type == 'vehicle'){
	    			// 	value.asset_no = value.vehicle ? value.vehicle.plate_no : "N/A"
	    			// }
	    			// if(value.asset_type == 'equipment'){
	    			// 	value.asset_no = value.equipment ? value.equipment.equipment_prep_no : "N/A"
	    			// }
	    			// if(value.asset_type == 'furnishing'){
	    			// 	value.asset_no = value.furnishing ? value.furnishing.asset_no : "N/A"
	    			// }
	    			// value.asset_type = value.asset_type.charAt(0).toUpperCase() + value.asset_type.slice(1)

	    			if(value.vehicle){
	    				value.plate_no = value.vehicle.plate_no
	    			}
	    			else{
	    				value.plate_no = "N/A"
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date = value.delivery_date
	    			}
	    			else{
	    				value.delivery_date = "N/A"
	    			}
	    			if(value.client_ref_no == "" || value.client_ref_no == null){
	    				value.client_ref_no_label = "N/A";
	    			}


	    			if(value.vehicle_type_id){
	    				value.vehicle_type_id_label = value.vehicle_type.setting_name
	    			}
	    			if(value.maker_model_id){
	    				value.maker_model_id_label = value.maker_model.setting_name
	    			}

	    			if(value.with_gps){
	    				value.with_gps_label = "Yes";
	    			}
	    			else{
	    				value.with_gps_label = "No";
	    			}

	    			if(value.delivery_date){
	    				value.delivery_date_label = moment.unix(value.delivery_date).format('MM/DD/YYYY')
	    			}
	    			else{
	    				value.delivery_date_label = "N/A"
	    			}

	    			// if(value.total_amount){
	    			// 	value.total_amount = value.total_amount;
	    			// }
	    			// else{
	    			// 	value.total_amount = "0.00"
	    			// }
					
	    			if(value.client_rate){
		    			value.client_rate= value.client_rate
		    		}
	    			
					else{
	    				value.client_rate = "0.00" 
	    			}

	    			if(value.is_subcon == false || value.is_subcon == 0){
    					value.vehicle_type_id_label = "Company Owned"
    				}
    				else{
    					if(value.vendor){
    						value.vehicle_type_id_label = value.vendor.vendor_name
    					}
    					else{
    						value.vehicle_type_id_label = "N/A"
    					}
    				}

					value.toll_fee = (value.billable) ? value.billable.toll_fee : '0.00';
					value.other_expenses = (value.billable) ? value.billable.other_expenses : '0.00';

					
					let total_amount = 0;

					if(value.billable) {
						total_amount = parseFloat(value.billable.toll_fee) + parseFloat(value.billable.other_expenses);
					}
					value.total_amount =  total_amount + parseFloat(value.subcon_rate);
					value.total_amount = (this.dataParams.tax_type && this.dataParams.tax_type == 'vat') ? value.total_amount + (value.total_amount * 0.12) : value.total_amount;
					value.total_amount = value.total_amount.toFixed(2)
					over_total_amount += parseFloat(value.total_amount);

					sub_total = total_amount + parseFloat(value.subcon_rate);
					tax_computation = (this.dataParams.tax_type && this.dataParams.tax_type == 'vat') ? sub_total * 0.12 : 0.00;
					

					return value; 
	    		}) : [];

				this.dataParams.total_amount = over_total_amount.toFixed(2);
				this.dataParams.sub_total = sub_total;
				this.dataParams.tax_computation = tax_computation;
	    		this.bookingList.total = this.bookingList.data.length;
	    		this.displayedBooking = [...this.bookingList.data];  
	    		this.displayedBooking.splice(((this.currentPage-1) + 20), this.bookingList.data.length) 

			})
			.catch(err => {
			this.$showLoading(false)
		}) 

		}, 

	    clearSearch(){
	    	this.dataParams = { 
				origin_id: "", 
				delivery_date_from: "",
				delivery_date_to: "", 
				customer_id:"",
				vendor_id: "",
				depot_id:"",
			}
	    }

 	}, 
}
</script>
